import React from "react";
import Slider from "react-slick";
import Img from "gatsby-image";
import { useInView } from "react-intersection-observer";
import ReactHtmlParser from "react-html-parser";
/* import TestimonialNextArrow from './testimonialNextArrow';
import TestimonialPrevArrow from './testimonialPrevArrow'; */
import SliderNextArrow from "./sliderNextArrow";
import SliderPrevArrow from "./sliderPrevArrow";
import { v4 as uuid } from "uuid";

function TestimonialSection({ title, slide = [], style = {} }) {
  const [ref, inView] = useInView();

  return (
    <div className="testimonial-section" ref={ref} style={style}>
      {title && <h2 className="title">{ReactHtmlParser(title)}</h2>}
      {inView ? (
        <div className="content-warpper">
          <Slider
            speed="1000"
            swipe="false"
            slidesToShow={1}
            swipeToSlide
            focusOnSelect
            className="slider2"
            nextArrow={<SliderPrevArrow short title="Next" uuid={uuid()} />}
            prevArrow={<SliderNextArrow short title="Previous" uuid={uuid()} />}
          >
            {slide.map(item => (
              <div className="slide-item" key={uuid()}>
                <div className="customer-profile">
                  {item.image ? (
                    <Img
                      className="profile-picture"
                      fluid={item.image.localFile.childImageSharp.fluid}
                    />
                  ) : null}
                  <h4 className="customer-info">
                    {ReactHtmlParser(item.title)}
                  </h4>
                </div>
                <div className="text">
                  {item.description2 ? (
                    <p>{ReactHtmlParser(item.description2)}</p>
                  ) : (
                    <p>{ReactHtmlParser(item.description)}</p>
                  )}
                </div>
              </div>
            ))}
          </Slider>
        </div>
      ) :  <div className="content-warpper"></div>}
    </div>
  );
}

TestimonialSection.defaultProps = {
  slide: [],
};

export default TestimonialSection;
