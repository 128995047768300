import React from "react";
import Img from "gatsby-image";
import Fade from "react-reveal/Fade";
import ReactHtmlParser from "react-html-parser";

function featureSection({ color, title, featureList, imageGrid, path }) {
  const shadow = index => {
    if (path === "/helmut-fx/" && (index < 2 || index > 6)) {
      return "feature boxShadow";
    } else if (path === "/helmut-io/" && index > 3) {
      return "feature boxShadow";
    } else if (path === "/helmut-co/" && index > 2) {
      return "feature boxShadow";
    }
    return "feature";
  };

  return (
    <div
      style={{
        background: `linear-gradient(#2a2a2a 0%,${color} 30%, ${color} 70%, #2a2a2a 100%)`,
      }}
      className="feature-section"
    >
      <h2 className="title">{ReactHtmlParser(title)}</h2>
      <div className="container">
        <div className="row feature-grid">
          {featureList.map((item, index) => {
            const isFullWidth = item.is_full_width === "yes";
            return (
              <div
                key={item.title}
                className={`col-md-${isFullWidth ? "12 text-center" : "6"} p-0`}
              >
                <div className={shadow(index)}>
                  {item.image && item.image.localFile && item.image.localFile.childImageSharp && (
                    <Img fluid={item.image.localFile.childImageSharp.fluid} />
                  )}
                  <h3 className="feature-title">
                    {ReactHtmlParser(item.title)}
                  </h3>
                  <p className="feature-text">{ReactHtmlParser(item.text)}</p>
                </div>
              </div>
            );
          })}
        </div>
        <div className="row image-grid">
          {imageGrid.map((item, index) => (
            <Fade key={item.src} bottom delay={100 * index}>
              <div className="col-md-4">
                <Img fluid={item} />
              </div>
            </Fade>
          ))}
        </div>
      </div>
    </div>
  );
}

export default featureSection;
