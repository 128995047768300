import React from 'react';
import { graphql } from 'gatsby';
import CoverImage from '../components/coverImage';
import ArticleWithCTA from '../components/articleWithCTA';
import ContactForm from '../components/ContactForm';
import FeatureSection from '../components/featureSection';
import TestimonialSection from '../components/TestimonialSection';
import ProductRedirect from '../components/ProductRedirect';

function ProductTemplate(props) {
  // const { transitionStatus, uri } = props;
  // const { setTransitionProps } = useContext(Context);
  // useEffect(() => {
  //   setTransitionProps({ transitionStatus, uri });
  // }, [transitionStatus]);
  const {
    data: {
      wordpressPage: {
        path,
        acf: {
          color_template: color,
          first_main_image: {
            localFile: {
              childImageSharp: {
                fluid: firstCoverImage,
              },
            },
          },
          second_main_image: {
            localFile: {
              childImageSharp: {
                fluid: secondCoverImage,
              },
            },
          },
          first_background_color_container: {
            description,
            title,
            background_image: {
              localFile: {
                childImageSharp: {
                  fluid: coverImageArticlewithCTA,
                },
              },
            },
          },
          presentation_section_container: {
            title: presentationTitle,
            description: presentationDescription,
            full_image: {
              localFile: {
                childImageSharp: {
                  fluid: presentationCoverImage,
                },
              },
            },
            feature_section_title: featureSectionTitle,
            feature_list,
            presentation_file_label,
            presentation_file: downloadFile,
          },
          grid_3x_with_images_container: {
            image_1: {
              localFile: {
                childImageSharp: {
                  fluid: imageOne,
                },
              },
            },
            image_2: {
              localFile: {
                childImageSharp: {
                  fluid: imageTwo,
                },
              },
            },
            image_3: {
              localFile: {
                childImageSharp: {
                  fluid: imageThree,
                },
              },
            },
          },
          testimonials_section_container: {
            title: testimonialTitle,
            slide: testimonialSlide,
          },
          redirect_section_container: redirectData,
        } = {
          first_main_image: {
            localFile: {
              childImageSharp: {
                fluid: null,
              },
            },
          },
        },
      },
      contactForm,
    },
    pathContext,
  } = props;
 
  return (
    <div>
      {/* <div
        className="hero-placeholder"
      /> */}
      <CoverImage fluid={firstCoverImage || undefined} />
      <ArticleWithCTA
        color={color}
        fluid={coverImageArticlewithCTA}
        title={title}
        text={description}
      /> 
      <ArticleWithCTA
        title={presentationTitle}
        text={presentationDescription}
        downloadFile={{ ...downloadFile, presentation_file_label }}
      />
      <CoverImage maxHeight={1009} fluid={presentationCoverImage} />
      <FeatureSection 
        path={path}
        color={color}
        title={featureSectionTitle}
        featureList={feature_list}
        imageGrid={[imageOne, imageTwo, imageThree]}
      />
      <TestimonialSection
        title={testimonialTitle}
        slide={testimonialSlide || undefined}
        style={!redirectData ? { paddingBottom: '189px' } : {}}
      /> 
      {redirectData ? <ProductRedirect data={redirectData} /> : null}
      <ContactForm
        fluid={secondCoverImage}
        color_template={color}
        contactForm={contactForm}
        lang={pathContext.lang}
      />
    </div>
  );
}

export const query = graphql`
  query($lang: String, $slug: String) {
    wordpressPage(
      slug: { eq: $slug }
      wpml_current_locale: { eq: $lang }
    ) {
      title
      template
      yoast_head
      path
      acf {
        color_template
        first_main_image {
          localFile {
            childImageSharp {
              fluid(maxHeight: 800, quality: 100) {
              ...GatsbyImageSharpFluid
              }
            }
          }
        }
        first_background_color_container {
          title
          description
          background_image {
            localFile {
              childImageSharp {
                fluid(maxHeight: 800, quality: 100) {
                ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
        presentation_section_container{
          title
          description
          full_image {
            localFile {
              childImageSharp {
                fluid(maxHeight: 1009, quality: 100) {
                ...GatsbyImageSharpFluid
                }
              }
            }
          }
          feature_section_title
          feature_list {
            title
            text
            is_full_width
            image {
              localFile {
                childImageSharp {
                  fluid(maxHeight: 800, quality: 100) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
          presentation_file_label
              presentation_file {
                title
                caption
                alt_text
                link
              }
        }
        grid_3x_with_images_container {
          image_1 {
            localFile {
              childImageSharp {
                fluid(maxHeight: 800, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          image_2 {
            localFile {
              childImageSharp {
                fluid(maxHeight: 800, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          image_3 {
            localFile {
              childImageSharp {
                fluid(maxHeight: 800, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
        second_main_image {
          localFile {
            childImageSharp {
              fluid(maxHeight: 800, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          # full_image
        }
        testimonials_section_container {
          title
          slide {
            title
            description2
            image {
              localFile {
                childImageSharp {
                  fluid(maxHeight: 211, quality: 100) {
                   ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
        redirect_section_container {
          title
          paragraph
          redirect_link {
            title
            url
          }
        }
      }
    } 
    wordpressAcfOptions {
      options {
        kontakt {
        de_label
        en_label
      }
      produkte {
        de_label
        en_label
      }
      referenzen {
        de_label
        en_label
      }
      sprache {
        de_label
        en_label
      }
        consent_checkbox_label
        consent_hint
        dropdown_label
        dropdown_placeholder
        # title

        time_window_dropdown_options {
          title
        }
        contact_icons_footer {
          email
          phone
        }
        bottom_footer_left {
          name
          english_name
          url
        }
        bottom_footer_right {
          name
          english_name
          url
        }
        copyright_text
        send_success_message
        dropdown_validation_error_message
      }
    }
    contactForm: wordpressPage(slug: {eq: "contact-form-options"}, wpml_current_locale: {eq:  $lang }) {
        acf {
          color_template
          contact_form_title
          description
          dropdown_label
          dropdown_placeholder
          consent_hint
          consent_checkbox_label
          send_success_message
          dropdown_validation_error_message
          time_window_dropdown_options {
            title
          }
          message_placeholder
          send_button
        }
      }
  }
`;
export default ProductTemplate;
