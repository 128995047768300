/* eslint-disable jsx-a11y/anchor-is-valid */
import { Link } from 'gatsby';
import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import ArrowRight from '../../static/assets/circle-arrow-right.svg';

function ProductRedirect({ data: { title, paragraph, redirect_link } }) {
  return redirect_link && (
    <div className="product-redirect">
      <div className="content">
        <h2>{ReactHtmlParser(title)}</h2>
        <h4>{ReactHtmlParser(paragraph)}</h4>
        <Link to={redirect_link?.url}>
          <h4>
            {redirect_link.title}
            {' '}
            <ArrowRight />
          </h4>

        </Link>
      </div>
    </div>
  );
}

export default ProductRedirect;
